import { corePost } from './core';
import { RequestBody } from './types';

type QueryProps = {
  key: string;
  vars?: Record<string, string | number>;
};

const NAMESPACE = 'shop';

export const shopClient = async (body: RequestBody) => {
  const response = await corePost(`${NAMESPACE}/storefront/public`, body);
  return response.data;
};

export const makeQuery = async (body: QueryProps) => {
  try {
    const res = await shopClient(body);
    const { userErrors = [], checkoutUserErrors = [], checkout } = res.data[body.key];

    if (userErrors.length) {
      throw userErrors[0].message;
    }

    if (checkoutUserErrors.length) {
      throw checkoutUserErrors[0].message;
    }

    return checkout;
  } catch (err: unknown) {
    const error =
      typeof err === 'string'
        ? err
        : 'Verifica que todos los datos sean correctos e inténtalo de nuevo';
    throw new Error(error);
  }
};
