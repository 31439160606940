import { mockInstance } from '../envClient';
import { CheckoutObject, LineItemNode } from '../../context/types';

const baseUrl = 'https://dev-platform.konfio.mx/core/';

const mockCheckoutId =
  'gid://shopify/Checkout/9c18cf01b37f385cb703b666af5c5132?key=7ad347f2d52397378af0cf8b8b6e5204';

const checkoutMock: CheckoutObject = {
  id: mockCheckoutId,
  ready: false,
  requiresShipping: false,
  note: null,
  paymentDue: {
    amount: '0.0',
    currencyCode: 'MXN'
  },
  webUrl:
    'https://konfio-shop-dev.myshopify.com/61127688360/checkouts/9c18cf01b37f385cb703b666af5c5132?key=7ad347f2d52397378af0cf8b8b6e5204',
  orderStatusUrl: null,
  taxExempt: false,
  taxesIncluded: false,
  currencyCode: 'MXN',
  totalTax: {
    amount: '0.0',
    currencyCode: 'MXN'
  },
  lineItemsSubtotalPrice: {
    amount: '0.0',
    currencyCode: 'MXN'
  },
  subtotalPrice: {
    amount: '0.0',
    currencyCode: 'MXN'
  },
  totalPrice: {
    amount: '0.0',
    currencyCode: 'MXN'
  },
  completedAt: null,
  createdAt: '2023-05-18T19:19:34Z',
  updatedAt: '2023-05-18T19:19:34Z',
  email: null,
  discountApplications: {
    pageInfo: {
      hasNextPage: false,
      hasPreviousPage: false
    },
    edges: []
  },
  appliedGiftCards: [],
  shippingAddress: null,
  shippingLine: null,
  customAttributes: [],
  order: null,
  lineItems: {
    pageInfo: {
      hasNextPage: false,
      hasPreviousPage: false
    },
    edges: []
  }
};

const mockProducts: Record<string, LineItemNode> = {
  'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MjA5OTUxMTIzMDYzMg==': {
    id: 'gid://shopify/CheckoutLineItem/420995112306320?checkout=9c18cf01b37f385cb703b666af5c5132',
    title: 'SmartPad',
    variant: {
      price: {
        amount: '2,199.0'
      },
      available: true
    }
  },
  'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MjA5ODMwNDY4MDEwNA==': {
    id: 'gid://shopify/CheckoutLineItem/420983046801040?checkout=9c18cf01b37f385cb703b666af5c5132',
    title: 'Pin Pad mini',
    variant: {
      price: {
        amount: '329.0'
      },
      available: true
    }
  }
};

mockInstance.onPost(`${baseUrl}shop/storefront/public`).reply(config => {
  const { key, vars } = JSON.parse(config.data);

  switch (key) {
    case 'checkoutCreate':
      return [
        200,
        {
          data: {
            data: {
              checkoutCreate: {
                checkout: checkoutMock
              }
            }
          }
        }
      ];
    case 'getCheckout':
      return [
        200,
        {
          data: {
            data: {
              node: checkoutMock
            }
          }
        }
      ];
    case 'checkoutLineItemsAdd': {
      const items = checkoutMock.lineItems.edges;

      const entryIndex = items.findIndex(e => e.variantId === vars.variantId);

      if (entryIndex > -1) {
        items[entryIndex].node.quantity += parseInt(vars.quantity, 10);
      } else {
        items.push({
          cursor: vars.variantId,
          node: {
            ...mockProducts[vars.variantId],
            quantity: parseInt(vars.quantity, 10)
          }
        });
      }

      return [
        200,
        {
          data: {
            data: {
              checkoutLineItemsAdd: {
                checkout: checkoutMock
              }
            }
          }
        }
      ];
    }
    case 'checkoutLineItemsUpdate': {
      const edges = checkoutMock.lineItems.edges;
      const index = edges.findIndex(e => e.node.id === vars.id);

      edges[index].node.quantity = parseInt(vars.quantity, 10);

      return [
        200,
        {
          data: {
            data: {
              checkoutLineItemsUpdate: {
                checkout: checkoutMock
              }
            }
          }
        }
      ];
    }
    default:
      return [404, { message: 'Unable to find requested page' }];
  }
});
