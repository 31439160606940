import { AxiosInstance } from 'axios';
import { ErrorResponse, RequestBody } from './types';
import { envClient } from './envClient';
import './mocks/shop';

export const headers = () => ({
  'Content-Type': 'application/json',
  'PLATFORM-ORIGIN': 'konfio-mx'
});

export const configureClient = (envClient: AxiosInstance) => {
  envClient.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      return Promise.reject(error.response);
    }
  );
  return envClient;
};

const coreClient = configureClient(envClient);

const handleErrors = (error: ErrorResponse) => {
  let data = null;
  if (error.data && error.data.error) {
    data = {
      error: {
        message: error.data.error.message,
        code: error.data.error.code
      }
    };
  } else {
    data = {
      error: {
        message: '',
        code: -1
      }
    };
  }
  return { data };
};

export const coreGet = async (url: string) => {
  try {
    const client = coreClient;
    const response = await client.get(url, { headers: headers() });
    return response.data;
  } catch (error: unknown) {
    return handleErrors(error);
  }
};

export const corePost = async (url: string, body: RequestBody) => {
  try {
    const client = coreClient;
    const response = await client.post(url, body, { headers: headers() });
    return response.data;
  } catch (error) {
    return handleErrors(error);
  }
};
