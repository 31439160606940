import * as React from 'react';
import { shopClient, makeQuery } from '../api/shop';
import { CheckoutObject } from './types';
import { isValid, isAfter, addMonths } from 'date-fns';

type ContextConfig = {
  loading: boolean;
  loadingCart: boolean;
  showCart: boolean;
  isPaymentPage: boolean;
  setIsPaymentPage: React.Dispatch<React.SetStateAction<boolean>>;
  addVariantToCart: (
    variantId: string,
    quantity: string,
    image: object,
    productTitle: string
  ) => void;
  updateLineItem: (checkoutId: string, lineItemID: string, quantity: string) => void;
  removeLineItem: (checkoutId: string, lineItemId: string) => void;
  checkout: CheckoutObject;
  images: { [id: string]: object };
};

type ProviderProps = {
  children: JSX.Element;
};

type CheckoutInfo = {
  id: string | null;
  first?: string;
  last?: string;
};

const defaultValues: ContextConfig = {
  loading: false,
  loadingCart: false,
  showCart: false,
  isPaymentPage: false,
  setIsPaymentPage: null,
  addVariantToCart: () => {},
  updateLineItem: () => {},
  removeLineItem: () => {},
  checkout: {
    lineItems: { edges: [] }
  },
  images: {}
};

export const StoreContext = React.createContext<ContextConfig>(defaultValues);

const isBrowser = typeof window !== `undefined`;
export const localStorageKey = `shopify_checkout_id`;

const getCheckoutInfo = (): CheckoutInfo => {
  const checkoutInfo = localStorage.getItem(localStorageKey);
  try {
    return JSON.parse(checkoutInfo || '{}');
  } catch {
    return { id: checkoutInfo || null };
  }
};

const isCheckoutValid = (checkoutInfo: CheckoutInfo): boolean => {
  if (checkoutInfo.id && checkoutInfo.id !== 'null') {
    const firstDate = new Date(checkoutInfo.first);
    const lastDate = new Date(checkoutInfo.last);
    const now = new Date();

    if (isValid(firstDate) && isValid(lastDate)) {
      return isAfter(addMonths(firstDate, 3), now) && isAfter(addMonths(lastDate, 1), now);
    }
    return false;
  } else {
    return false;
  }
};

export const StoreProvider: React.FC<ProviderProps> = ({ children }) => {
  const [checkout, setCheckout] = React.useState(defaultValues.checkout);
  const [images, setImages] = React.useState(defaultValues.images);
  const [loading, setLoading] = React.useState(false);
  const [loadingCart, setLoadingCart] = React.useState(false);
  const [showCart, setShowCart] = React.useState(defaultValues.showCart);
  const [isPaymentPage, setIsPaymentPage] = React.useState(defaultValues.isPaymentPage);

  const setCheckoutItem = (checkout: CheckoutObject) => {
    if (isBrowser) {
      localStorage.setItem(
        localStorageKey,
        JSON.stringify({
          id: checkout.id,
          first: new Date(checkout.createdAt),
          last: new Date(checkout.updatedAt)
        })
      );
    }
    setCheckout(checkout);
  };

  React.useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutInfo = isBrowser ? getCheckoutInfo() : { id: null };
      try {
        if (isCheckoutValid(existingCheckoutInfo)) {
          const {
            data: { node: existingCheckout }
          } = await shopClient({
            key: 'getCheckout',
            vars: { id: existingCheckoutInfo.id }
          });

          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout);
            return;
          }
        }
        const newCheckout = await makeQuery({ key: 'checkoutCreate' });
        setCheckoutItem(newCheckout);
      } catch (e) {
        localStorage.setItem(localStorageKey, JSON.stringify({ id: null }));
        setLoading(false);
      }
    };
    initializeCheckout();
  }, []);

  React.useEffect(() => {
    checkout.lineItems.edges.length > 0 || isPaymentPage ? setShowCart(true) : setShowCart(false);
  }, [isPaymentPage, checkout.lineItems.edges.length]);

  const addVariantToCart = async (
    variantId: string,
    quantity: string,
    image: object,
    productTitle: string
  ) => {
    try {
      setLoading(true);
      const checkoutId = checkout.id;
      const lineItemsToUpdate = {
        variantId,
        quantity: parseInt(quantity, 10)
      };

      const res = await makeQuery({
        key: 'checkoutLineItemsAdd',
        vars: { checkoutId, ...lineItemsToUpdate }
      });
      setCheckout(res);
      setImages({ ...images, [productTitle]: image });
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const updateLineItem = async (checkoutId: string, lineItemID: string, quantity: string) => {
    try {
      setLoadingCart(true);
      const lineItemsToUpdate = {
        id: lineItemID,
        quantity: parseInt(quantity, 10)
      };

      const res = await makeQuery({
        key: 'checkoutLineItemsUpdate',
        vars: { checkoutId, ...lineItemsToUpdate }
      });

      setCheckout(res);
      setLoadingCart(false);
    } catch (err) {
      setLoadingCart(false);
    }
  };

  const removeLineItem = async (checkoutId: string, lineItemId: string) => {
    try {
      setLoadingCart(true);
      const res = await makeQuery({
        key: 'checkoutLineItemsRemove',
        vars: { checkoutId, lineItemIds: lineItemId }
      });
      setCheckout(res);
      setLoadingCart(false);
    } catch (err) {
      setLoadingCart(false);
    }
  };

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        updateLineItem,
        removeLineItem,
        checkout,
        images,
        loading,
        loadingCart,
        showCart,
        isPaymentPage,
        setIsPaymentPage
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
